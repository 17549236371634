import React, {createContext, useState, useContext} from "react"
import axios from "axios"

const newApiRoot = "https://api.marineblue-g.com"


// 20240301 フッター予約ボタンの都合で状態管理・共有範囲変更
const CastDataContext = createContext()
export const useCastData =()=>useContext(CastDataContext)

export default function CastDataProvider({children}){

    const [hvnId, setHvnId]=useState(null)
    const [castDiaryData, setCastDiaryData] = useState([])

    const getCastDiary =(hvnDiaryUrl)=>{
        
        axios({
            method: "get",
            url: `${newApiRoot}/v2/diary_cast?t=` + hvnDiaryUrl
        })
        .then((res=>{
            // console.log(res)
            setCastDiaryData(res.data)
        }))
    }

    const updateHvnId=(id)=>{
        setHvnId(id)

        return null
    }

    const resetHvnId=()=>{
        setHvnId(null)
        return null
    }

    const resetCastData=()=>{
        setCastDiaryData([])
    }



    return(
        <CastDataContext.Provider value={{
            hvnId,
            updateHvnId, resetHvnId,
            castDiaryData, getCastDiary, resetCastData,
        }}>
            {children}
        </CastDataContext.Provider>
    )
}